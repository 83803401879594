import { jsx as o, Fragment as a, jsxs as i } from "react/jsx-runtime";
import { useState as d } from "react";
import { Grid as e, CircularProgress as y } from "@mui/material";
import { Button as C } from "../atoms/Button/Button.js";
import "../../Chatbot.style-780e19b4.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import { asset as x } from "../../assets/icons/index.js";
import { d as F } from "../../ArrowForward-ead9dabe.js";
import "../../runtime-6393d96e.js";
import "../../TableContext-04bf54ad.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../server.browser-ec1ee1ca.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import "../../ChatbotContext-74017a6b.js";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../map-85279df3.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import { Input as c } from "../atoms/Input/Input.js";
import { Typography as n } from "../atoms/Typography/Typography.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
const eo = ({ errorsComponent: p, loading: s, email: g, onReset: u }) => {
  const [m, h] = d(""), [l, f] = d("");
  function w(t) {
    t == null || t.preventDefault(), u(m, l);
  }
  return /* @__PURE__ */ o(a, { children: /* @__PURE__ */ o(e, { container: !0, sx: r.main, children: /* @__PURE__ */ i(e, { sx: r.loginContainer, children: [
    /* @__PURE__ */ i(e, { sx: r.topContainer, children: [
      /* @__PURE__ */ o(e, { sx: r.logo, children: /* @__PURE__ */ o("img", { src: x.loginPageLogo, width: "236" }) }),
      /* @__PURE__ */ i(e, { component: "form", onSubmit: w, sx: r.loginForm, children: [
        /* @__PURE__ */ o(n, { sx: r.ResetTitleText, color: "#141414", children: "Reset your password" }),
        /* @__PURE__ */ i(n, { children: [
          "Hi, ",
          g
        ] }),
        /* @__PURE__ */ o(n, { sx: r.infoText, color: "#878787", children: "Please enter your new password." }),
        /* @__PURE__ */ o(e, { sx: r.error, children: p }),
        /* @__PURE__ */ o(
          c,
          {
            label: "Password",
            id: "password",
            name: "password",
            required: !0,
            type: "password",
            placeholder: "Password",
            sx: r.inputPass,
            value: m,
            onChange: (t) => h(t.target.value)
          }
        ),
        /* @__PURE__ */ o(
          c,
          {
            label: "Confirm Password",
            id: "confirmPassword",
            name: "confirmPassword",
            required: !0,
            type: "password",
            placeholder: "Confirm Password",
            sx: r.inputPass,
            value: l,
            onChange: (t) => f(t.target.value)
          }
        ),
        /* @__PURE__ */ o(C, { type: "submit", sx: r.loginButton, disabled: s, children: s ? /* @__PURE__ */ i(a, { children: [
          s && /* @__PURE__ */ o(y, { size: 24 }),
          "  "
        ] }) : /* @__PURE__ */ i(a, { children: [
          /* @__PURE__ */ o(n, { type: "bodyText1", color: "#FFFFFF", sx: r.resetText, children: "Change Password" }),
          /* @__PURE__ */ o(F, {})
        ] }) }),
        /* @__PURE__ */ o("div", { style: r.actions })
      ] })
    ] }),
    /* @__PURE__ */ o(e, { sx: r.noAccount, children: /* @__PURE__ */ o("div", { onClick: () => {
      window.open("mailto:support@highway9.com");
    }, children: /* @__PURE__ */ o(n, { type: "heading3", color: (t) => t.palette.primary.main, sx: r.pointer, children: "Contact Support" }) }) })
  ] }) }) });
}, r = {
  main: (p) => ({
    width: "100vw",
    height: "100vh",
    overflow: "none",
    backgroundColor: p.palette.primary.main,
    backgroundImage: `url(${x.loginPageBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }),
  logo: {
    textAlign: "center",
    marginBottom: "40px"
  },
  loginContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "100%",
    mx: "8px",
    maxHeight: "95vh",
    maxWidth: "500px"
  },
  topContainer: {
    padding: "3rem 3.5rem 2rem"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px"
  },
  noAccount: {
    borderTop: "1px solid #EFEFEF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "32px",
    gap: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  loginButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    marginBottom: "16px",
    "&::before ": {
      content: "''",
      width: 0
    }
  },
  infoText: {
    fontSize: ".8rem",
    marginTop: "8px"
  },
  ResetTitleText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "8px"
  },
  resetText: {
    textAlign: "center"
  },
  inputEmail: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  inputPass: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  error: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
    width: "100%",
    "& .MuiAlert-root": {
      borderRadius: "8px"
    }
  }
};
export {
  eo as default
};
